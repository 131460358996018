<template>
  <div>
    <div class="website_header">
      <div class="website_header_content">
        <a v-if="config.showPageTitle" href="/" class="website_title">{{config.companyShortName}}</a>
        <div class="website_search_container">
          <input class="website_search_input" type="text" :placeholder="searchInput" @focus="searchInput=''" @blur="searchInput='输入关键字搜索'">
          <span class="website_search_icon" @click="_toggleShowSign(true)"></span>
        </div>
        <div class="website_sign_container" @click="_toggleShowSign(true)">
          <span class="website_sign_in">登陆</span>
          <span class="website_sign_up active">注册</span>
        </div>
      </div>
    </div>
    <router-view @toggle-show="_toggleShowSign"/>
    <sign v-if="showSign" @toggle-show="_toggleShowSign"></sign>
    <cs-footer/>
  </div>
</template>

<script>
import sign from '@/components/sign'
import config from './configCenter.json'
import CsFooter from '@/components/footer'
export default {
  name: 'App',
  data() {
    return {
      showSign: false,
      searchInput: '输入关键字搜索',
      config
    }
  },
  components: {
    sign,
    CsFooter
  },
  methods: {
    _toggleShowSign() {}
  }
}
</script>

<style lang="less">
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    position: relative;
    line-height: 1;
    background-color: #efeeeb;
    overflow-x: hidden;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a{
    text-decoration: none;
  }
  #app{
    position: absolute;
    left: 50%;
    width: 1920px;
    height: 100%;
    margin-left: -960px;
  }
  .clearfix:after {
    display: table;
    content: "";
    line-height: 0;
    clear: both;
  }
  .left{
    float: left;
  }
  .right{
    float: right;
  }
  .fontYaHei {
    font-family: "Microsoft YaHei", "微软雅黑";
  }
  .divider {
    border-top: 1px solid #e8e3d3;
  }


  .website_header{
    background-color: #f9f8f3;
    border-bottom: 1px solid #e6e5e2;
    box-shadow: 0px 2px 4px 0px rgba(7, 0, 2, 0.08);
  }
  .website_header_content{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1000px;
    height: 86px;
    margin: 0 auto;
    padding-left: 20px;
  }
  .website_title{
    margin-right: 250px;
    font-size: 18px;
    font-weight: 700;
    color: #666;
    cursor: pointer;
  }
  .website_search_container{
    margin-right: 150px;
    border: 1px solid #e6e5e2;
    font-size: 0;
  }
  .website_search_input{
    width: 236px;
    height: 32px;
    padding-left: 10px;
    line-height: 32px;
    font-size: 16px;
    font-weight: 400;
    color: #999;
    border: none;
    outline: none;
    vertical-align: middle;
  }
  .website_search_icon{
    display: inline-block;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    cursor: pointer;
    background: url("./assets/search_icon.png") center no-repeat;
  }
  .website_sign_container{
    display: flex;
    width: 136px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    color: #666;
    border: 1px solid #e6e5e2;
    border-radius: 5px;
    overflow: hidden;
  span{
    flex: 1;
    text-align: center;
    cursor: pointer;
    &:hover{
       background: #f5f4ed;
    }
    &:active{
      background-color: #c5c0ae;
    }
  }
  .website_sign_in{
    border-right: 1px solid #e6e5e2;
  }
  .website_sign_up{
    border-left: 1px solid #e6e5e2;
  }
  }

  .termText {
    padding: 50px 0;
    width: 60em;
    margin: 0 auto;
    color: #444;
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1.5;
  }
</style>
