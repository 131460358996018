<template>
  <div class="termText">
    <h1>隐私政策：</h1>
    <p>尊敬的用户：</p>
    <p>您好！</p>
    <p>{{config.companyFullName}}（下称“{{config.companyShortName}}”或“我们”）非常重视您的隐私和个人信息，并始终致力于保护您的隐私。为了您更好地使用{{config.companyProductName}}服务，我们专门制定了本隐私政策，我们希望向您说明我们在您使用{{config.companyProductName}}服务时收集何种信息以及如何收集、使用和保护这些信息。</p>
    <p>
      在使用{{config.companyProductName}}服务前，请您务必仔细阅读我们的隐私政策。除非贵方已阅读并接受本协议的全部条款，否则请贵方立即停止使用{{config.companyProductName}}服务。一旦贵方
      <strong>贵方勾选或点击</strong>&nbsp;
      <strong>“</strong>&nbsp;
      <strong>我已阅读并同意《隐私政策》</strong>&nbsp;
      <strong>”</strong>&nbsp;
      <strong>按钮</strong>或以第三方平台账号登录或实际使用{{config.companyProductName}}服务，即视为贵方已充分理解并接受本隐私政策。
    </p>
    <p>一、定义</p>
    <p>个人信息是指能够单独或者与其他信息结合用于识别特定自然人身份或者反应自然人活动情况的各种信息，包括姓名、身份证件号码、通讯联系方式、住址、账号密码、财产信息、IP地址、常用设备信息、网络行为记录、个人位置信息等。</p>
    <p>二、信息的收集</p>
    <p>您知悉并同意，您在使用{{config.companyProductName}}服务时，我们将根据您的使用和选择，可能收集、存储或使用与您有关的信息：</p>
    <p>（一）用户主动提交的信息</p>
    <p>当您在创建注册用户账号、申请播客、上传节目、发送错误报告或参与抽奖、在线调查等行为时，主动向我们提交的个人信息和资料。</p>
    <p>（二）用户使用过程中的信息</p>
    <p>当您在使用{{config.companyProductName}}服务时，我们可能会自动记录一些您的信息，包括但不限于：您的设备信息、URL、IP地址、浏览器类型、使用语言、访问日期和时间等。</p>
    <p>同时，为方便您使用{{config.companyProductName}}服务，{{config.companyProductName}}服务器会自动生成一个Cookie，并发至贵方计算机，在本地终端上存储贵方的登陆信息。多数浏览器的初始设置都接受Cookie，如贵方不接受，可以改变浏览器的设置，以禁止使用Cookie。在您选择接受cookies的情况下，我们在有需要时通过cookies技术自动采集相关日志或其他与您相关的非个人身份信息。如您拒绝cookies，则有可能无法登陆或使用{{config.companyProductName}}依赖于cookies的服务或功能。</p>
    <p>（三）第三方账号信息</p>
    <p>当您使用第三方平台（微信、微博、QQ）账号快速登录、使用{{config.companyProductName}}服务时，我们会在您的同意下获取您第三方平台账户和相关信息（昵称、头像等）。</p>
    <p>三、信息的使用</p>
    <p>为了向用户提供更好的{{config.companyProductName}}服务，我们可能会把您的信息用于以下用途：</p>
    <p>（1）向您提供{{config.companyProductName}}服务；</p>
    <p>（2）帮助我们开发、交付和改进{{config.companyProductName}}FM及其服务；</p>
    <p>（3）参与在线调查、反馈等活动；</p>
    <p>（4）向您推荐可能感兴趣的内容；</p>
    <p>（5）向您提供个性化服务；</p>
    <p>（6）其他经您同意的使用。</p>
    <p>四、信息保护</p>
    <p>我们采取各种预防措施，包括行政、技术和实际的措施，以保障您的个人信息免遭丢失、盗用和误用，以及被擅自取阅、披露、更改或销毁。为确保您的个人信息的安全，我们将公司的隐私和安全准则告知全体公司雇员，并将在公司内部严格执行隐私保护措施。</p>
    <p>我们可能会与第三方合作向用户提供{{config.companyProductName}}的相关服务，并要求该第三方承担与我们同等的保护用户隐私的责任。如您在使用{{config.companyProductName}}服务（如留言、评论等）的过程中主动公开、上传、发布或向第三方提供您的个人信息的，其他用户可能会收集您的个人信息，对于您决定在上述情形中提交或分享的个人信息，您应自行负责。</p>
    <p>在如下情况下，我们会遵照您的意愿或法律的规定披露您的个人信息：</p>
    <p>（1）事先获得您的授权；</p>
    <p>（2）根据法律法规、法律程序或诉讼的要求；</p>
    <p>（3）按照相关政府主管部门的要求；</p>
    <p>（4）为维护我们的合法权益。</p>
    <p>
      <strong>五、未成年人信息保护</strong>
    </p>
    <p>
      <strong>我们不会在知情的情况下收集未成年人的个人信息。除非所在地法律允许并且监护人同意，未成年人请不要注册账户或发送自己的姓名、住址、电话、邮件地址等个人信息给我们。如果我们不小心收集到了未成年人的信息，我们在知情后会尽快删除。如果您认为我们可能不当地持有来自于或关于未成年人的信息，请联系我们。</strong>
    </p>
    <p>六、适用范围</p>
    <p>除某些特定服务外，{{config.companyShortName}}提供的所有服务均适用本隐私政策。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本隐私政策的一部分。如相关特定服务的隐私政策与本隐私政策有不一致之处，适用该特定服务的隐私政策。</p>
    <p>我们可随时对隐私政策加以更新。如果我们对隐私政策作出重大变更，我们会通过电子邮件、或常规的信件或{{config.companyProductName}}平台页面公告、系统通知或私信传送等方式告知用户，该等通知于发送之日视为已送达收件人并生效。</p>
    <p>
      如果您对我们的隐私政策或数据处理有任何疑问或顾虑，请联系我们：
      <br />举报中心
      <br />举报邮箱：ppyuewan@lizhi.fm
      <br />客服中心
      <br />关注微信公号“PP约玩”输入您的问题
      <br />工作时间：09:00~12:00、14:00~23:00
    </p>
  </div>
</template>

<script>
import config from "../../configCenter.json";
export default {
  data() {
    return {
      config,
    };
  },
};
</script>
