<template>
  <div class="sign_wrapper">
    <div class="sign_layer" @click="$emit('toggle-show', false)"></div>
    <div class="sign_container">
      <span class="sign_closer" @click="$emit('toggle-show', false)"></span>
      <div class="sign_phone_container clearfix">
        <div class="sign_phone_place_chosen_container left" @click.stop="toggleShowPlaceList(showPlaceList)">
          <span class="sign_phone_place_chosen">{{ placeChosen }}</span>
          <span v-if="!showPlaceList" class="sign_phone_place_down"></span>
          <span v-else class="sign_phone_place_up"></span>
        </div>
        <ul class="sign_phone_place_container" v-if="showPlaceList">
          <li @click="chosePlace(index)" class="sign_phone_place_item" :class="{'active': placeChosenIndex === index}" v-for="(item, index) in placeList" :key="item">{{ item }}</li>
        </ul>
        <input class="sign_phone_input left" type="number" ref="phone" @input="inputLimit('phone', 11)" :placeholder="phoneInput" @focus="phoneInput=''" @blur="phoneInput='手机号码'">
      </div>
      <div class="code_container">
        <input class="code_input" type="number" ref="code" @input="inputLimit('code', 6)" :placeholder="codeInput" @focus="codeInput=''" @blur="codeInput='验证码'">
        <span v-if="canGetCode" class="code_btn" @click="getCode">获取验证码</span>
        <span v-else class="code_btn_ts">等待({{ ts }}s)..</span>
      </div>
      <div class="sign_warning_container">
        <p class="sign_warning" v-if="showWarn">{{ showWarnText }}</p>
      </div>
      <div class="sign_in" @click="signin">
        登陆
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      data() {
        return {
          placeChosenIndex: 0,
          placeList: ['中国(+86)', '中国香港(+852)', '中国台湾(+866)', '新加坡(+65)', '加拿大(+1)'],
          showPlaceList: false,
          showWarn: false,
          showWarnText: '手机号码或验证码错误',
          canGetCode: true,
          ts: 60,
          timeout: null,
          ivl: null,
          phoneInput: '手机号码',
          codeInput: '验证码'
        }
      },
      computed: {
        placeChosen() {
          return this.placeList[this.placeChosenIndex]
        }
      },
      beforeDestroy() {
        if(this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
        if(this.ivl){
          clearInterval(this.ivl);
          this.ivl = null;
        }
      },
      methods: {
        chosePlace(i) {
          this.placeChosenIndex = i;
          this.showPlaceList = false;
        },
        toggleShowPlaceList(flag) {
          this.showPlaceList = !flag;
        },
        toggleShowWarning() {
          this.showWarn = true;
          if(this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
          }
          this.timeout = setTimeout(() => {
            this.showWarn = false;
          }, 3000)
        },
        signin() {
          if (this.$refs.phone.value === '' || this.$refs.phone.value.length !== 11) {
            this.showWarnText = '请填写手机号码'
          } else {
            this.showWarnText = '手机号码或验证码错误'
          }
          this.toggleShowWarning()
        },
        getCode() {
          if (this.$refs.phone.value === '' || this.$refs.phone.value.length !== 11) {
            this.showWarnText = '请填写手机号码'
            this.toggleShowWarning()
            return;
          }
          this.canGetCode = false;
          if(this.ivl){
            clearInterval(this.ivl);
            this.ivl = null;
          }
          this.ivl = setInterval(() => {
            this.ts -= 1;
            if (this.ts < 0) {
              clearInterval(this.ivl);
              this.ivl = null;
              this.ts = 60;
              this.canGetCode = true;
            }
          }, 1000)
        },
        inputLimit(str, len) {
          let v = this.$refs[str].value;
          if (v.length > len) {
            this.$refs[str].value = v.slice(0,len)
          }
        }
      }
    }
</script>

<style lang="less" scoped>
.sign_wrapper{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}
.sign_layer{
  width: 100%;
  height: 100%;
  background-color: #efeeeb;
  opacity: .9;
}
.sign_container{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 500px;
  height: 300px;
  margin: auto;
  padding: 60px 15px 15px;
  border: 1px solid #d6d3c8;
  background-color: #f9f8f2;
}
.sign_closer{
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background: url("../assets/msg_close.png") 0 0 no-repeat;
  cursor: pointer;
}

.sign_phone_container{
  position: relative;
  margin-bottom: 30px ;
}
.sign_phone_place_chosen_container{
  position: relative;
  width: 160px;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  padding-left: 10px;
  font-size: 16px;
  color: #534836;
  background-color: #fffef8;
  border-radius: 6px;
  border: solid 1px #e7e6dd;
  cursor: pointer;
}
.sign_phone_place_down{
   display: inline-block;
   position: absolute;
   right: 10px;
   top: 22px;
   border-top: 5px solid #efb313;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-bottom: 5px solid transparent;
 }
.sign_phone_place_up{
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 17px;
  border-bottom: 5px solid #efb313;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid transparent;
}
.sign_phone_place_container{
  position: absolute;
  top: 50px;
  width: 160px;
  color: #534836;
  background-color: #fffef8;
  border: solid 1px #e7e6dd;
  border-top: none;
}
.sign_phone_place_item{
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  cursor: pointer;
  &.active{
    background-color: #cec9b7;
  }
  &:hover{
    color: #fff;
    background-color: #6cb5ff;
  }
}
.sign_phone_input{
  width: 216px;
  height: 45px;
  padding-left: 21px;
  color: #534836;
  font-size: 16px;
  outline: none;
  border: 1px solid #e7e6dd;
  border-radius: 6px;
  background-color: #fffef8;
}
.code_container{
}
.code_input{
  width: 280px;
  height: 48px;
  padding: 0 10px;
  color: #534836;
  font-size: 16px;
  outline: 0;
  vertical-align: top;
  border-radius: 4px;
  background-color: #fffef7;
  border: solid 1px #e7e6dd;
}
.code_btn, .code_btn_ts{
  display: inline-block;
  width: 100px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #908878;
  font-size: 16px;
  vertical-align: top;
  border: 1px solid #d6d3c8;
  background-color: #f5f2ea;
  border-radius: 8px;
}
.code_btn{
  cursor: pointer;
  &:hover{
    background-color: #f8f6ef;
  }
  &:active{
    background-color: #c5c0ae;
  }
}
.sign_warning_container{
  height: 32px;
}
.sign_warning{
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  color: red;
}
.sign_in{
  width: 180px;
  height: 53px;
  line-height: 53px;
  text-align: center;
  font-size: 20px;
  color: #908878;
  border: 1px solid #d6d3c8;
  background-color: #f5f2ea;
  border-radius: 8px;
  cursor: pointer;
  &:hover{
    background-color: #f8f6ef;
  }
  &:active{
    background-color: #c5c0ae;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}
</style>
