<template>
  <div class="termText">
    <p>{{config.companyProductName}}（下称：平台）作为中立的网络在线音频平台技术服务提供者，平台上的节目内容由节目发布者（下称：第三方）自主研发、并独立运营。鉴于，平台中的相关节目由第三方自行上传且数量庞大，但{{config.companyShortName}}作为信息存储服务提供者无法对他人通过{{config.companyProductName}}服务上传、下载以及传播的海量播客节目进行事先审查，为了建立良好的平台秩序和保护各权利人的合法权益，根据相关法律之规定，{{config.companyProductName}}特制定本指引，以保护权利人的合法权益。</p>
    <p>
      <strong>一、流程</strong>
    </p>
    <p>
      <strong>（一）通知{{config.companyProductName}}</strong>
    </p>
    <p>若权利人认为第三方上传的任何节目内容侵犯其合法权益，请按以下要求提交相关材料进行投诉，通知{{config.companyProductName}}：</p>
    <p>
      权利人应向{{config.companyProductName}}提出书面通知书
      <strong>【书面通知书的内容请参考附件</strong>
      <strong>1</strong>
      <strong>中给出的模板】</strong>，通知书的内容应包括但不限于以下内容：
    </p>
    <p>
      <strong>1</strong>
      <strong>、权利人主体信息和相关材料</strong>
    </p>
    <p>权利人的姓名（名称）、联系方式、地址及营业执照（单位）、身份证（个人）、相关授权证明等证明权利人主体资格的材料；</p>
    <p>
      <strong>2</strong>
      <strong>、权利人要求</strong>
    </p>
    <p>权利人要求删除或者断开链接的节目相关内容的准确信息（包括节目名称、节目上传者（昵称和波段号）等）和网络地址；</p>
    <p>
      <strong>3</strong>
      <strong>、投诉的具体权利类型</strong>
    </p>
    <p>权利人应在书面通知书中明确投诉的节目内容的具体权利类型，如表演权、信息网络传播权、肖像权等。</p>
    <p>
      <strong>4</strong>
      <strong>、构成侵权的初步证明材料</strong>
    </p>
    <p>该初步证明材料应包括：</p>
    <p>
      <strong>（</strong>
      <strong>1</strong>
      <strong>）权利人拥有权利的权属证明材料：</strong>包括但不限于相关有权机构颁发的版权证书、作品首次公开发表或发行日期证明材料（如作品首次公开发表的网络地址或期刊扫描件等）、授权证明文件等权属证明；
    </p>
    <p>
      <strong>（</strong>
      <strong>2</strong>
      <strong>）被投诉方提供的服务构成侵权的证明：</strong>包括但不限于被投诉方提供的节目相关内容构成对权利人的版权、肖像权等侵权的有效证明材料等，如侵权截图对比等。
    </p>
    <p>
      <strong>5</strong>
      <strong>、权利人保证</strong>
    </p>
    <p>
      <strong>权利人的通知书应包含以下保证：</strong>权利人在通知书中的陈述和提供的相关材料皆是真实、有效和合法的，并保证承担和赔偿因{{config.companyProductName}}根据权利人的通知而删除或者断开有关侵权节目内容的链接或相关内容而给{{config.companyProductName}}公司造成的任何损失，包括但不限于{{config.companyProductName}}因向被投诉方或用户赔偿而产生的损失及{{config.companyProductName}}名誉、商誉损害等。
    </p>
    <p>
      <strong>（二）{{config.companyProductName}}转送</strong>
    </p>
    <p>{{config.companyProductName}}作为中立的平台服务者，收到权利人符合本指引要求的通知书后，{{config.companyProductName}}会尽快按照相关法律法规的规定进行处理，并在符合法律规定要求的情形下将权利人的通知书转送给被投诉方。</p>
    <p>
      <strong>（三）被投诉人反通知</strong>
    </p>
    <p>
      被投诉方在收到{{config.companyProductName}}转送的权利人向{{config.companyProductName}}提交的相关投诉材料后，若被投诉方不认可权利人的投诉，可以参照“（一）通知{{config.companyProductName}}”中的相关指引
      <strong>并在三个工作日内（逾期视为同意投诉人的投诉事实）</strong>提供反通知书及相关证明材料给{{config.companyProductName}}
      <strong>【书面反通知书的内容请参考附件</strong>
      <strong>2</strong>
      <strong>中给出的模板】</strong>。{{config.companyProductName}}根据反通知情况可能会将被投诉方提供的反通知书及相关证明材料转送给权利人，若权利人对于被投诉方的意见及其提供的相关材料有异议的，{{config.companyProductName}}建议权利人另行通过行政投诉、诉讼等方式直接和被投诉方解决相关问题。如果权利人有新的并可充分推翻被投诉方意见的证明材料的，也可向{{config.companyProductName}}提供。
    </p>
    <p>
      <strong>二、联系方式</strong>
    </p>
    <p>1、将前述书面通知书、反通知书及其他相关证明材料的纸质版本全部扫描后通过电子邮件发送至{{config.companyProductName}}指定电子邮箱：</p>
    <p>
      <strong>投诉接收：</strong>
      <a href="mailto:copyright@lizhi.fm">copyright@lizhi.fm</a>
      <strong>；</strong>
    </p>
    <p>
      <strong>邮件名称标明：</strong>
      <strong>“</strong>
      <strong>【侵权投诉】权利人（机构</strong>
      <strong>/</strong>
      <strong>老师）</strong>
      <strong>+</strong>
      <strong>事宜简写</strong>
      <strong>”</strong>
      <strong>。</strong>
    </p>
    <p>2、通过邮寄的方式将前述书面通知书、反通知书及其他相关证明材料的纸质版本邮寄至{{config.companyProductName}}指定的以下地点：</p>
    <p>公司总部地址：广东省广州市天河区黄埔大道中309号自编3-07A法务部</p>
    <p>邮编：510000</p>
    <p>
      <strong>三、注意事项</strong>
    </p>
    <p>1、本指引中的权利人，指拥有与节目内容相关的版权等合法权益的原始所有人或经原始所有人合法授权的代理人，包括自然人、法人或其他组织等。</p>
    <p>2、为了确保投诉或反通知的真实性和有效性，权利人或被投诉人的书面通知书、反通知书及其他相关证明材料，原则上应提供原件，不能提供原件的，应提供复印件（在复印件上应有权利人的签章），若材料涉外的，应按照法律的规定进行公证转递，并同时提供相应的公证转递材料。</p>
    <p>3、本指引中的书面通知书或书面反通知书，均应包括通知书或反通知书本身及相关的主体资格材料、权属证明、侵权证明或不侵权证明等材料。</p>
    <p>4、若权利人已经因为被投诉人提供的内容向相关政府部门或法院提起行政投诉或诉讼的，请在提交通知书时，将相关受理证明及提交政府部门或法院的证据材料一同提交给{{config.companyProductName}}，这将有利于权利人的投诉的处理。</p>
    <p>（完）</p>

    <p>
      <a
        href="http://mlychee.com/doc/%E4%BE%B5%E6%9D%83%E6%8A%95%E8%AF%89%E9%80%9A%E7%9F%A5%E4%B9%A6%EF%BC%88%E6%A8%A1%E6%9D%BF%EF%BC%89.doc"
        download
      >附件 1：侵权投诉通知书（模板）.doc</a>
    </p>

    <p>
      <a
        href="http://mlychee.com/doc/%E4%BE%B5%E6%9D%83%E6%8A%95%E8%AF%89%E5%8F%8D%E9%80%9A%E7%9F%A5%E4%B9%A6%EF%BC%88%E6%A8%A1%E6%9D%BF%EF%BC%89.doc"
        download
      >附件 2：侵权投诉反通知书（模板）.doc</a>
    </p>
  </div>
</template>

<script>
import config from "../../configCenter.json";
export default {
  data() {
    return {
      config,
    };
  },
};
</script>