<template>
  <div class="termText">
    <h1>{{config.companyProductName}}服务协议：</h1>
    <p>尊敬的用户：</p>
    <p>您好！</p>
    <p>感谢您选择{{config.companyProductName}}产品与服务（简称“{{config.companyProductName}}”或“{{config.companyProductName}}服务”）。在注册{{config.companyProductName}}账号及使用{{config.companyProductName}}服务前，请用户审慎阅读、充分理解以下所述条款的内容，特别是限制、排除或者免除责任的条款。</p>
    <p>本服务协议的所有条款即构成贵方与{{config.companyFullName}}（简称“{{config.companyShortName}}”）之间关于贵方使用{{config.companyProductName}}服务所达成的协议（简称“本协议”），用于规范双方业务行为，明确双方的权利和义务，保障双方合法权益。用户在使用{{config.companyProductName}}服务之前，请仔细阅读本协议，如用户不同意本服务协议的任何条款，请不要使用{{config.companyProductName}}产品与服务。贵方勾选或点击“我已阅读并同意《{{config.companyProductName}}服务协议》”按钮或以第三方平台账号登录或实际使用{{config.companyProductName}}服务，即视为贵方已接受本协议的全部条款，本协议即开始生效。</p>
    <p>1. 定义</p>
    <p>1.1 “{{config.companyProductName}}”，是指{{config.companyShortName}}及其关联公司经营的一款网络声音产品（包括但不限于{{config.companyShortName}}经营的lizhi.fm网站、{{config.companyProductName}}电脑端及移动客户端软件等），为用户提供数据存储、管理、分享的信息存储空间服务和互联网直播服务等。</p>
    <p>1.2 “用户”，是指任何使用{{config.companyProductName}}及/或通过{{config.companyProductName}}搜索、收听、上传、下载、发布声音节目的使用者，包括但不限于法人、其他组织或自然人。</p>
    <p>1.3 “主播”，是指在{{config.companyProductName}}上传、发布声音节目的用户。</p>
    <p>1.4 “声音节目”，是指由主播使用{{config.companyProductName}}上传、发布的其拥有完整著作权及相关合法权利的录音制品（包括但不限于主播或第三方录制的原创音频、音乐作品音频、影视类作品音频、文字作品的有声读物等），以及与之相关的声音名称、声音封面、主播名、主播肖像、声音内容简介等。</p>
    <p>2. 协议生效及变更</p>
    <p>2.1所有拟使用、使用{{config.companyProductName}}全部或部分服务的用户，应当首先同意本协议的所有条款，特别是免除或者限制责任的条款，限制、免责条款可能以加粗形式提示贵方注意。如果贵方不接受本协议，请不要使用{{config.companyProductName}}全部或部分服务。</p>
    <p>2.2即使用户在注册账号过程中没有选择“已查看并同意《{{config.companyProductName}}服务协议》”，贵方实际访问、浏览及使用{{config.companyProductName}}提供的各项服务、业务的行为，即视为对本协议的完全接受，并同意受本协议的约束。</p>
    <p>2.3 本协议是用户与{{config.companyShortName}}之间关于用户使用{{config.companyProductName}}服务所订立的协议，在用户开通或使用某项特定服务时，可能会涉及某项服务的单独协议，所有单独协议为本协议不可分割的组成部分，与本协议具有同等法律效力。</p>
    <p>2.4 {{config.companyShortName}}保留依自主判断在将来的任何时间变更、修改、增加或删除本协议的权利，一旦发生服务条款的变动，{{config.companyShortName}}将公示修改的内容，所有修改的内容均构成本协议的一部分。当用户使用{{config.companyProductName}}的任何服务时，应接受{{config.companyProductName}}随时提供的与该服务相关的规则或说明，并且此规则或说明均构成本服务条款的一部分。如果贵方不同意服务条款的修改，请立即主动取消、停止已经获得的服务；如果贵方继续使用{{config.companyProductName}}服务，则视为贵方已经接受服务条款的修改。</p>
    <p>3. 信息存储空间服务郑重声明</p>
    <p>3.1 {{config.companyShortName}}郑重提请您注意，{{config.companyProductName}}目前仅向用户提供录音技术服务及信息网络存储空间服务，{{config.companyProductName}}本身不直接上传、提供内容，不对主播传输、存储、发布的内容进行任何修改或编辑，无法对经由{{config.companyProductName}}信息存储空间服务上传、发布、共享声音节目的内容以及主播的上述使用行为进行全面控制，因此不保证内容的真实性、合法性、正确性、完整性。</p>
    <p>3.2 主播有权使用{{config.companyProductName}}信息存储空间服务，声音节目均由主播录制、上传及发布，包括录制音频、背景音乐、曲库、内容均由主播自行提供。主播承诺保证对其上传、发布的声音节目享有著作权及相关权利，不存在任何侵犯第三方合法权益（包括但不限于著作权、商标权、专利权等）的情形，并保证其拥有合法充分的权利将声音节目通过{{config.companyProductName}}信息存储空间服务上传、发布、使用、复制、传播和供用户搜索、收听和下载。声音节目及其内容如涉及到第三方的合法权利，主播应在上传和发布之前获得相关权利人的授权。</p>
    <p>3.3 {{config.companyShortName}}非常重视和尊重版权及其他知识产权的保护，{{config.companyShortName}}已在本协议及特定协议中约定用户使用{{config.companyProductName}}服务不得侵害他人之合法权益，并尽商业上合理的注意义务设置相应的技术措施及规范防止用户通过{{config.companyProductName}}服务侵权他人之合法权益。</p>
    <p>
      如用户发现{{config.companyProductName}}中之任何内容或用户使用{{config.companyProductName}}服务产生的内容涉嫌侵害其合法权益的，应按照《信息网络传播权保护条例》有关规定及时发送邮件到
      <strong>copyright@@lizhi.fm</strong>
      并提供权属证明、初步侵权证据等所需材料,具体操作请查阅《版权投诉指引》。{{config.companyShortName}}将在收到权利通知后的合理时间内按照相关规定进行处理。
    </p>
    <p>3.4 如主播所上传、发布的声音节目存在违法或侵权情形的，{{config.companyShortName}}在收到权利人之权利通知后会依法移除声音节目，并有权采取包括但不限于以下处罚措施：警告、限制或禁止使用全部或部分服务功能、暂时或永久封禁账号（声音）、回收注销账号、扣罚保证金等。</p>
    <p>3.5 因声音节目或其内容引起的第三方权利主张，由主播自行解决并承担全部的法律责任，与{{config.companyShortName}}无关。若因声音节目侵权导致{{config.companyShortName}}承担赔偿责任的，主播应赔偿{{config.companyShortName}}所遭受的所有损失，包括但不限于：赔偿款、诉讼费、律师费、公证费、差旅费等直接、间接损失。</p>
    <p>4. 用户使用规则</p>
    <p>
      <strong>4.1&nbsp;</strong>&nbsp;
      <strong>用户资格</strong>&nbsp;
      <strong>
        <br />
        <br />
      </strong>
      <strong>您确认，在您开始使用</strong>&nbsp;
      <strong>/</strong>&nbsp;
      <strong>注册程序使用我方平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。</strong>&nbsp;
      <strong>
        <br />
        <br />
      </strong>
      <strong>若您不具备前述与您行为相适应的民事行为能力，则应获得监护人的知情同意，您及您的监护人应依照法律规定承担因此而导致的相应的责任。特别地，如果您是未成年人，请在您的监护人的同意和指导下访问和</strong>&nbsp;
      <strong>/</strong>&nbsp;
      <strong>或使用我方平台网站。</strong>
    </p>
    <p>4.2 非商业使用</p>
    <p>用户承诺，未经{{config.companyShortName}}书面同意，用户不得利用{{config.companyProductName}}服务进行销售或其他商业使用等用途。</p>
    <p>4.3 合法使用</p>
    <p>
      <strong>（</strong>&nbsp;
      <strong>1</strong>&nbsp;
      <strong>）用户使用{{config.companyProductName}}服务时必须遵守中华人民共和国法律、法规和政策，不得利用{{config.companyProductName}}服务制作、复制、发布、传播如下内容：</strong>
    </p>
    <p>（a）反对宪法所确定的基本原则的；</p>
    <p>（b）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>（c）损害国家荣誉和利益的；</p>
    <p>（d）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>（e）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>（f）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p>（g）侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p>（h）含有法律、法规和政策禁止的其他内容的信息。</p>
    <p>
      <strong>（</strong>&nbsp;
      <strong>2</strong>&nbsp;
      <strong>）{{config.companyShortName}}一直致力于为用户提供文明、健康、规范有序的网络环境，用户不得利用{{config.companyShortName}}发布以下内容或从事以下行为：</strong>
    </p>
    <p>
      <strong>(a)</strong>&nbsp;
      <strong>诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；</strong>
    </p>
    <p>（b）色情网站、成人用品网站等含有任何性或性暗示的内容；</p>
    <p>（c）国家明令禁止或未取得国家许可销售的产品，或假冒、伪劣产品或未经授权销售的产品；</p>
    <p>（d）组织、宣传网络兼职，或虚假人气、贩卖虚拟货币，或骚扰、垃圾广告或信息的；</p>
    <p>（e）涉及他人隐私、个人信息或资料的；</p>
    <p>（f）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
    <p>（g）其他侵犯其他用户或第三方合法权益的。</p>
    <p>
      <strong>（</strong>&nbsp;
      <strong>3</strong>&nbsp;
      <strong>）用户不得利用{{config.companyProductName}}服务实施以下行为：</strong>
    </p>
    <p>（a) 未经{{config.companyShortName}}书面许可，对{{config.companyProductName}}软件进行反向工程、反向汇编、反向编译或以其他方式获取{{config.companyProductName}}软件源代码；</p>
    <p>（b）未经{{config.companyShortName}}书面许可，对于{{config.companyProductName}}软件相关信息，擅自实施包括但不限于下列行为：使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、借助{{config.companyProductName}}软件发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等删除{{config.companyProductName}}软件及其他副本上所有关于版权的信息、内容；</p>
    <p>（c）一切利用第三方平台、工具或其他不正当手段对声音进行刷粉、点赞、评论、分享等行为；</p>
    <p>（d）使用{{config.companyProductName}}服务过程中，对{{config.companyProductName}}服务交互数据进行复制、更改、修改的；</p>
    <p>（e）利用{{config.companyProductName}}服务推广其他第三方运营平台或互相推广；</p>
    <p>（f）提交、发布虚假信息，或冒充、利用他人名义；</p>
    <p>（g）诱导、欺骗、强迫其他用户关注、点击链接页面或分享信息；</p>
    <p>（h）虚构、编造、散布虚假或片面事实，欺骗、误导他人的；</p>
    <p>（i）注册信息、申请认证资料与实际情况不一致的；</p>
    <p>（j）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播的；</p>
    <p>（i）其他利用{{config.companyProductName}}服务从事违反、犯罪活动，或任何干扰{{config.companyProductName}}服务正常运营，或违反{{config.companyProductName}}平台规则和要求的；</p>
    <p>若{{config.companyShortName}}发现用户有上述行为的，所有责任由用户单方承担。同时{{config.companyShortName}}有权根据违规情节，有权采取包括但不限于以下处罚措施：警告、限制或禁止使用全部或部分服务功能、暂时或永久冻结封禁账号、回收注销账号、扣罚保证金等。</p>
    <p>4.4 用户账号使用规范</p>
    <p>（1）用户使用{{config.companyProductName}}服务时，需注册{{config.companyProductName}}账号。用户在创建账号时，必须提供用户名和密码，以及完成注册所要求提交的个人资料信息。</p>
    <p>（2）主播应具备完全民事行为能力。若主播是自然人的，应提供真实姓名、住址、电子邮箱、联系电话等信息；若主播是法人或其他组织的，应提供名称、住址、联系人等信息。</p>
    <p>（3）用户应确保注册资料的真实性和准确性，并保证仅使用其个人信息或依法获得授权的信息注册{{config.companyProductName}}账号。如注册资料有任何变动，须及时更新，以便{{config.companyShortName}}确认贵方的身份，或为贵方提供密码找回等服务。</p>
    <p>（4）用户根据要求完成注册申请后，可获得特定{{config.companyProductName}}账号的使用权，但账号的所有权始终归{{config.companyShortName}}所有。</p>
    <p>（5）{{config.companyShortName}}特别提醒贵方妥善保管您的用户名和密码，不要将您的账号信息透露给任何其他人，不要向他人透露、复制、转让注册账户或授权许可他人使用注册账户。当使用完{{config.companyProductName}}服务后，应安全退出。</p>
    <p>（6）{{config.companyShortName}}仅根据服务器接收到的用户名、密码进行身份确认，在用户名、密码准确的情况下，{{config.companyShortName}}将该登陆行为视为系贵方亲自实施。用户发现任何账号异常情况，应立即通知{{config.companyShortName}}请求采取应急措施，但该账号登陆后可能导致的损失由贵方承担，{{config.companyShortName}}不负任何责任。</p>
    <p>（7）用户应遵守本协议的各项条款，正确、适当地使用本服务，否则，{{config.companyShortName}}有权依据本协议终止对贵方{{config.companyProductName}}账号提供服务。同时，根据贵方的违规情节，{{config.companyShortName}}有权采取包括但不限于以下处罚措施：暂时、永久冻结封禁账号（、回收账号、扣罚保证金等，由此产生的损失由用户自行承担。</p>
    <p>（8）如果用户超过180天未使用、或停止使用本服务或服务被终止或取消，{{config.companyShortName}}有权回收账号且无需事先通知贵方即可从服务器上永久地删除用户的数据。服务停止、终止或取消后，{{config.companyShortName}}没有义务向用户返还任何数据。用户同意{{config.companyShortName}}有权行使上述权利且不需对用户或第三方承担任何责任。</p>
    <p>4.5 关于用户名和昵称使用：</p>
    <p>（1）不得使用党和国家领导人或其他知名人士的真实姓名、字号、艺名、笔名作为用户名和昵称；</p>
    <p>（2）不得使用国家机构或其他机构的名称作为用户名和昵称；</p>
    <p>（3）不得使用和其他知名网友之名相同或近似的用户名和昵称；</p>
    <p>（4）不得使用不文明、不健康，或带攻击性、侮辱性的用户名和昵称；</p>
    <p>（5）请勿使用易产生歧义、引起他人误解的用户名和昵称；</p>
    <p>（6）不得使用其他具有不良影响的用户名和昵称；</p>
    <p>对于违反以上规定或产生不良后果的用户名和昵称，{{config.companyShortName}}有权删除而不必事先通知。</p>
    <p>4.6 关于发表及评论内容规则</p>
    <p>（1）遵守相关法规，严禁发表违反法律法规及社会主义道德规定的内容；</p>
    <p>（2）使用文明用语，不得张贴对任何人进行人身攻击、谩骂、诋毁的言论；</p>
    <p>（3）不得张贴未经公开报道、未经证实的消息；</p>
    <p>（4）不得张贴与所在论坛主题无关的消息、言论和图片；</p>
    <p>（5）转贴文章应注明原始出处和时间；</p>
    <p>对于违反以上规定的内容，在不需要通知和解释的情况下，{{config.companyShortName}}有权予以删除，并根据违规情节作出处罚措施。</p>
    <p>4.7 关于贴图规则</p>
    <p>（1）不得张贴宣扬反动、封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪等不符合国家法律规定的以及任何包含种族、性别、宗教歧视性和猥亵性的图片；</p>
    <p>（2）不得出现带有侮辱性、挑衅、辱骂其他人以及不健康内容的图片；</p>
    <p>请勿使用易产生歧义、引起他人误解的图片；</p>
    <p>（3）摘录、转贴的图片请注明出处及作者，禁止张贴侵犯他人著作权、版权等知识产权的图片；</p>
    <p>（4）不得张贴与论坛主题无关的图片；</p>
    <p>{{config.companyShortName}}有权在不需要通知和解释的情况下删除违反以上规定的图片，并根据违规情节作出处罚措施。。</p>
    <p>5. 知识产权</p>
    <p>5.1 {{config.companyProductName}}由{{config.companyShortName}}独立自主开发完成，其产品、服务所涉及的著作权、商标权、专利权、商业秘密等知识产权、其他相关权利均独立归属{{config.companyShortName}}所有。与{{config.companyProductName}}服务相关的所有信息，包括但不限于：{{config.companyProductName}}商标标识、网页/产品版面设计、界面设计、图标、色彩、文字表述、数据、代码、电子文档及其组合等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护。</p>
    <p>5.2 用户使用{{config.companyProductName}}服务只能在本协议以及相应的授权许可范围内使用{{config.companyShortName}}的知识产权，未经授权禁止任何超范围使用行为, 不论是否以商业为目的。</p>
    <p>5.3 用户在使用{{config.companyProductName}}服务时所上传、发布、共享的声音节目,知识产权归用户所有。除非{{config.companyShortName}}收到相反通知，{{config.companyShortName}}将视该用户依法享有完整的著作权，并为声音节目的著作权人。</p>
    <p>5.4 用户点击同意本协议或通过{{config.companyProductName}}服务上传、发布、共享声音节目的行为，即视为用户对{{config.companyShortName}}的授权，用户确认其将该声音节目以无偿、非独占性、永久性授权{{config.companyShortName}}使用。{{config.companyShortName}}有权通过{{config.companyProductName}}服务对该声音节目进行使用，包括对声音节目进行编辑、推广、展示，或许可{{config.companyShortName}}合作伙伴使用，或提供给{{config.companyProductName}}其他用户搜索、收听、下载、分享等。</p>
    <p>6. 隐私政策</p>
    <p>
      {{config.companyShortName}}非常重视贵方的隐私和个人信息，
      <strong>建议未成年用户请监护人仔细阅读本隐私权政策</strong>
      。为此我们专门制定了《隐私政策》，希望向您说明我们在贵方使用{{config.companyProductName}}服务时收集何种信息以及如何收集、使用和保护这些信息。
    </p>
    <p>在使用{{config.companyProductName}}服务前，请您务必仔细阅读我们的隐私政策。除非贵方已阅读并接受本协议的全部条款，否则请贵方立即停止使用{{config.companyProductName}}服务。一旦贵方选择注册了{{config.companyProductName}}账号或以第三方平台账号登录或实际使用{{config.companyProductName}}服务，即视为贵方已充分理解并接受本隐私政策。</p>
    <p>7. 责任限制与不可抗力</p>
    <p>7.1 {{config.companyShortName}}按照现有技术和条件所能达到的现状向用户提供{{config.companyProductName}}服务，并尽最大努力确保服务的安全性和稳定性。{{config.companyShortName}}不担保所提供的网络服务一定能满足用户的要求，也不担保提供的信息存储空间容量充足或其提供的网络服务不会被中断，且对服务的及时性、安全性、出错发生，以及信息是否能准确、及时、顺利的传送均不作任何担保，也不承担任何法律责任，但{{config.companyShortName}}将尽力减少因此给用户造成的损失和影响。</p>
    <p>7.2 为了向用户提供优质服务，{{config.companyShortName}}有权不经用户同意而变更、改变、删除、增加{{config.companyProductName}}服务，也可能暂停或彻底终止本服务，而不承担任何法律责任。</p>
    <p>7.3 用户理解，{{config.companyShortName}}审核用户注册信息以及声音节目仅仅是对此类信息的形式审查，{{config.companyShortName}}没有能力也不可能对用户的注册信息和上传的声音节目是否真实、是否侵权等方面进行实质审查。{{config.companyShortName}}对用户之注册信息及其上传、发布的声音节目不做任何担保、认可、认同，也不因此承担任何形式的法律责任。</p>
    <p>7.4 在使用{{config.companyProductName}}服务过程中可能存在来自多方面的风险，包括威胁性、诽谤性、非法的、或侵犯他人合法权利的风险信息，用户需自行承担该风险。{{config.companyShortName}}对用户经由{{config.companyShortName}}提供的服务所获得的信息亦不做任何担保，包括真实性、合法性、非侵权性等，尤其是用户通过{{config.companyShortName}}提供的服务获得的商业广告信息、交友信息等。用户须自行甄别信息的真实性并谨慎预防可能存在的风险，因此而产生的任何直接、间接、偶然、附带或衍生的损失或损害，{{config.companyShortName}}不承担任何责任。</p>
    <p>7.5 用户理解，{{config.companyProductName}}服务容易受到互联网安全问题的困扰，并由于互联网技术的不稳定性，可能遭遇基础电信运营商移动通信网络的故障、技术缺陷、覆盖范围限制、不可抗力、计算机病毒、黑客攻击、服务器系统崩溃等风险，上述风险可能导致{{config.companyProductName}}服务中断或数据损失，{{config.companyShortName}}对此等不可抗力因素导致的损失不承担责任。</p>
    <p>8. 通知</p>
    <p>所有发给用户的通知都可通过电子邮件、常规的信件或在{{config.companyProductName}}服务内通过系统通知、私信、或页面公告等方式进行传送。{{config.companyShortName}}将通过上述方法之一将消息传递给用户，告知他们服务条款的修改、服务变更等。</p>
    <p>9. 法律责任</p>
    <p>9.1 如果发现或收到他人举报或投诉用户违反本协议约定的，{{config.companyShortName}}有权不经通知随时对相关内容进行删除，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用全部或部分服务功能、暂时或永久冻结封禁账号、回收注销账号。</p>
    <p>9.2 用户理解并同意，{{config.companyShortName}}有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</p>
    <p>9.3 用户理解并同意，因用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何损失，包括但不限于：赔偿款、诉讼费、律师费、公证费、差旅费等直接、间接损失，用户应当全额赔偿{{config.companyShortName}}及该第三方，并使之免受损害。</p>
    <p>10. 法律适用及管辖</p>
    <p>本协议适用中华人民共和国法律（香港、澳门及台湾地区除外），所产生之纠纷应提交{{config.companyFullName}}住所地人民法院管辖。</p>
  </div>
</template>

<script>
import config from "../../configCenter.json";
export default {
  data() {
    return {
      config,
    };
  },
};
</script>
