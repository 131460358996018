<template>
    <div class="container">
      <div class="content_container clearfix">
        <ul class="menu_list left">
          <li class="menu_item">
            <div id="playlist-btn" class="playlist-btn active">
              <i class="playlist-icon"></i>正在播放
            </div>
          </li>
          <li class="menu_item" @click="$emit('toggle-show', true)">
            <div id="history-list-btn" class="history-list-btn">
              <i class="history-list-icon"></i>播放历史
            </div>
          </li>
        </ul>
        <div class="music_list_container">
          <div class="music_list_title">
            <span>歌曲</span>
            <span>歌手</span>
            <span>时长</span>
          </div>
          <ul class="music_list">
            <li class="music_item">
              <span class="music_rank">1</span>
              <span class="music_title">{{ music.name }}</span>
              <span class="music_author">{{ music.singer }}</span>
              <span class="music_like" @click="$emit('toggle-show', true)"></span>
              <span class="music_download" @click="$emit('toggle-show', true)"></span>
              <span class="music_time">{{ duration }}</span>
            </li>
          </ul>
        </div>
        <div class="music_detail_container right">
          <img class="music_detail_img" :src="music.coverUrl" :alt="music.names">
          <h1 class="music_detail_title">{{ music.name }}</h1>
          <p class="music_detail_author">{{ music.singer }}</p>
          <div class="music_detail_lyrics" v-html="music.lyricContent"></div>
        </div>
      </div>
      <div class="play_wrapper">
        <div class="controller-box">
          <div class="content clearfix">
            <div class="controller-box-ctrl">
              <i class="controller-prev" @click="$emit('toggle-show', true)"></i>
              <i class="controller-play" :class="{'controller-pause': audioPlay}" ref="controllerPlay" @click="toggleAudioPlay"></i>
              <i class="controller-next" @click="$emit('toggle-show', true)"></i>
              <i id="controller-repeater" class="controller-repeater" :class="repeatClass" @click="toggleRepeatClass"></i>
            </div>
            <div class="controller-box-volume">
              <!--<p class="current-audio-title">&nbsp;</p>-->
              <i class="controller-volume-icon"></i>
              <div class="rangeslider-wrap">
                <div class="rangeslider" id="controller-volume" @click.stop="setVolume">
                  <div class="rangeslider__fill" ref="rangesliderVolumeFill" style="width: 100%"></div>
                </div>
              </div>
            </div>
            <div class="controller-box-position">
              <div class="rangeslider-wrap">
                <div class="rangeslider" ref="positionController" @click.stop="controlPosition">
                  <div class="rangeslider__fill" ref="rangesliderFill" style="width: 0px;"></div>
                  <div class="rangeslider__handle" style="left: 0px;"
                       ref="rangesliderHandle" @click.stop @mousedown="moveHandle"></div>
                  <div class="rangeslider__cache" ref="rangesliderCache" style="width: 0%;"></div>
                </div>
              </div>
              <div id="position" class="controller-position">{{ currentTime }}</div>
              <div id="duration" class="controller-duration">{{ duration }}</div>
            </div>
          </div>
        </div>
      </div>
      <audio ref="audio" class="audio" :src="music.audioUrl"
            preload="auto">Your browser does not support the audio element.</audio>
    </div>
</template>

<script>
  const volumWidth = 172;
  let pcWidth;

    export default {
      data() {
        return {
          audioPlay: false,
          repeatClassIndex: 0,
          repeatClassArr: ['', 'repeater-back', 'repeater-one'],
          currentTime: '00\'00"',
          duration: '00\'00"',
          music: {},
        }
      },
      created() {
        this.music = this.$route.query.music;
      },
      mounted() {
        if (!this.music.audioUrl) {
          this.$router.push('/')
        } else {
          this.toggleAudioPlay();
          this._getPcWidth();
          this._addAudioEvent();
        }
      },
      computed: {
        repeatClass() {
          return this.repeatClassArr[this.repeatClassIndex];
        }
      },
      methods: {
        controlPosition(event) {
          let e = event || window.event;
          let currentPercent = (e.offsetX / pcWidth).toFixed(2) ;
          this.$refs.audio.currentTime = Math.floor(currentPercent * this.$refs.audio.duration);
          this._setCurrentTime();
          if (this.$refs.audio.paused) {
            this.$refs.audio.play();
            this.audioPlay = true;
          }
        },
        setVolume(event){
          let e = event || window.event;
          let volum = Math.floor(e.offsetX / volumWidth * 100);
          this.$refs.rangesliderVolumeFill.style.width = volum + '%';
          this.$refs.audio.volume = volum / 100;
        },
        moveHandle(event) {
          let e = event || window.event;
          let self = this, handle = this.$refs.rangesliderHandle;
          let moveOffsetX, downOffsetX = e.offsetX
          handle.onmousemove = function (e) {
            moveOffsetX = e.offsetX;
            let offsetPercent = ((moveOffsetX - downOffsetX) / pcWidth).toFixed(2);
            self.$refs.audio.currentTime += Math.floor(offsetPercent * self.$refs.audio.duration);
            self._setCurrentTime();
            return false;
          }
          handle.onmouseup = function() {
            if (self.$refs.audio.paused) {
              self.$refs.audio.play();
              self.audioPlay = true;
            }
            handle.onmousemove = null;
            handle.onmouseup = null;
          }
          handle.onmouseout = function() {
            if (self.$refs.audio.paused) {
              self.$refs.audio.play();
              self.audioPlay = true;
            }
            handle.onmousemove = null;
            handle.onmouseup = null;
            handle.onmouseout = null;
          }
        },
        toggleAudioPlay() {
          this.audioPlay = !this.audioPlay;
          if (this.$refs.audio.paused) {
            this.$refs.audio.play();
          } else {
            this.$refs.audio.pause();
          }
        },
        toggleRepeatClass() {
          this.repeatClassIndex++;
          if (this.repeatClassIndex === this.repeatClassArr.length) {
            this.repeatClassIndex = 0;
          }
        },
        _addAudioEvent() {
          let self = this, audio = this.$refs.audio
          audio.oncanplay = function () {
            self.duration = self._formatTime(audio.duration)
          }
          audio.ontimeupdate = function () {
            let cachedPercent = Math.floor(audio.buffered.end(audio.buffered.length - 1) / audio.duration * 100)
            self.$refs.rangesliderCache.style.width = cachedPercent + "%"
            self._setCurrentTime();
          }
          audio.onended = function() {
            self.$refs.audio.currentTime = 0;
            self._setCurrentTime();
            self.audioPlay = false;
          }
        },
        _setCurrentTime() {
          let ct = this.$refs.audio.currentTime;
          let dura = this.$refs.audio.duration;
          let percent = (ct / dura).toFixed(2);
          this.currentTime = this._formatTime(ct);
          this.$refs.rangesliderHandle.style.left = Math.floor(percent * pcWidth) + 'px';
          this.$refs.rangesliderFill.style.width = Math.floor(percent * pcWidth) + 'px';
        },
        _formatTime(ts) {
          let time  = Math.floor(ts)
          let min = Math.floor(time / 60)
          let sec = Math.floor(time % 60)
          min = min < 10 ? `0${min}` : min
          sec = sec < 10 ? `0${sec}` : sec
          return `${min}'${sec}"`
        },
        _getPcWidth() {
          let pc = this.$refs.positionController;
          if (pc.currentStyle) {
            pcWidth = pc.currentStyle.width.slice(0, -2) - 0;
          } else if (window.getComputedStyle) {
            pcWidth = window.getComputedStyle(pc).width.slice(0, -2) - 0;
          }
        }
      }
    }
</script>

<style scoped lang="less">
@import "index";
</style>
