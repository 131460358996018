<template>
  <footer>
    <div class="footer_container clearfix fontYaHei">
      <div class="left">
        <div>
          <a target="_blank"
            :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + (config.policeLicense || '').match(/\d+/)[0]"
            v-if="config.policeLicense" style="display: inline-block; text-decoration: none; line-height: 20px;">
            <img
              src=" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAEUElEQVR4AY2UY2Bd6xJAd23bthXVthXrtae2bdu2bduNbeec2PZbN3NR68fnmTXcW+k7c9BPBwRUTvG9q7qzd5nNtS2LnaKdL08FdfVf6fwYlPayu8f1ZVePTxyVdtysNav09Vg7aQBnlo3g8ETjLHkj3XHQHwFJDS78bKcpOhW12WOuxdsp7Qgxr0fMrNb43Z/E1dNz0Cury6Mt+gCFfgmUcNLtF70arjWcXYsNSFraGNd8RXDRqYv3oCbYK3nJOtGHS3d3ITI5njtuiwM/BKYT0CTozsQMraqDMTVQkb29Oa51qsjAoXlVvPo2xrFiCV4VLwbPzZkyZy096vci4s2i0CiPHY2+A5Kyr8PerfNQlJVc2GZAvGlN8Q73NrVwr1oJp+JlxFOslLxEmTXh2ePl5MkznW3rF5DhtaL1V8BopxfVs5+PfLN723KUPAu5t7IrwX1q4qUUxL1WZTy61JYhewlb9njaLhAgSxcvgtj1z0mLq/MJ6HR93oy0s7qcObRWPOTuIX1SV2uJMlYGTXHd3xnN8+EEbu8rYUuBsHs9S4Ds27kGnPWJ8ru15hPQ5/H6gxEH2vPu+lKUwruYbmlJ5ttePBnUkPddauA+vjVe8/TwmaaNx+Y+EPw/Fo0zl2h492wDyTe7oX65/cQnoNfFaSF+6zqQYD2D6l0OiGWBEbG6C279mmLTuQ4eZu1w6dYQzQwtXMZ3pljBCVRqvYl4z/kE7tMl4LrK5xPQ+4pFiv1CPbjXn9nr9opl7iulyWleSrzj3Yj6uM3TFgNyxwulDIoykUnLt/P/dyNw2dCZ4CcTAz8B1fdW3vI/2Yts23G4PN2AUm07gxRdbpUsydvKRXnfqBy2uUN60bZLCybl1UIpvRjr+wvJcp5B8O2RRNut2/cJqLm36oPvru4EXxoLzirW7jgkXooXucOCISWHYF5zGMUVE7mTN2YvXg0+pmjumaC+PICojwvffgKGvV+S5XlcH49dI1Hv6UyizTTcXy5k7wJjpi7azN0zKuyujsDYbCFjhsxgzvzVxLy2JPxkZ3yO9EZ9czCxXiuiPwGzkmyNpC38zw7CbWcf3OY2Jv2tvngAnoMlT8Tf6CftAXHTZEWKKLISbqS1GZmxd7v8B/wEDf9gEh/6cjTOazrjvr0TcXbjiHxmSOit4YQ/MZAzCS6T8D/UC+/9A4iwnob69bToePW5Xt99enEBH7sH3VCFRb43JuKdBaEPxhL1cix/n1+ZE2dtIXCkePIW6zqTsLcqfC/oR/0QmByw+XFa8FzpK0m0DAFIfgRIxIMR4pmkhdDHRjKItptIkmY2SeG7r3wHlN9QpOeaPeqn+snhj4aSaDWG2OfDiLg1kOjcc/j1/uIxsR8NCX8xRPJGtP/KoNjw00t5vSLvT3+wnodml7deY2jyekWXo34XR7z3PjLcWX11hCbsnpHG74LR+9CnU59GOh3Y6nltTb9PoC/GX21leVIj+B9LAAAAAElFTkSuQmCC"
              style="float: left;" />
            <p style="float: left; line-height: 20px; margin: 0px 0px 0px 5px; color: rgb(147, 147, 147);">
              {{ config.policeLicense }}</p>
          </a>
        </div>
        <p v-if="config.icpLicense">
          <a target="_blank" href="https://beian.miit.gov.cn">{{ config.icpLicense }}</a>
        </p>
        <p v-if="config.wangwenLicense">
          <a target="_blank" :href="config.wangwenLicenseUrl">{{ config.wangwenLicense }}</a>
        </p>
        <p>{{ config.companyFullName }}</p>
      </div>
      <div class="right">
        <a target="_blank" href="#/agreement">服务协议</a>
        <a target="_blank" href="#/privacy">隐私政策</a>
        <a target="_blank" href="#/copyright">版权投诉指引</a>
      </div>
    </div>
  </footer>
</template>

<script>
import config from "../configCenter.json";

export default {
  data() {
    return {
      config
    }
  }
}
</script>

<style lang="less">
footer {
  margin-top: 45px;
  border-top: 1px solid #b8b5a9;
}

.footer_container {
  width: 1000px;
  margin: 0 auto;
  padding: 45px 0 20px;
  color: #867f6f;
  font-size: 14px;

  a {
    color: #867f6f;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  p {
    line-height: 24px;
  }

  .right {
    padding-top: 24px;

    a {
      margin-right: 20px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>